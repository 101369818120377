import React, {FC} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {CheckCircleOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Button, message, Skeleton} from 'antd';

import FormLayout from '../../components/FormLayout/FormLayout';
import {ACT_SUCCESS, INDEX} from '../../config/routes';
import { IFormAct } from '../../shared/forms/forms';
import {recordActivate, recordActivateVariables} from '../../shared/graphql/__generated__/recordActivate';
import {recordByCode, recordByCodeVariables} from '../../shared/graphql/__generated__/recordByCode';
import {MUTATION_RECORD_ACTIVATE, QUERY_RECORD_BY_CODE} from '../../shared/graphql/recordActivate';
import {Contacts} from '../index/Contacts/Contacts';

import ActForm from './ActForm';
import image from './document.svg'

import cls from './Act.module.scss';

const Act: FC = () => {

    const navigate = useNavigate();
    const {code} = useParams();
    const query = useQuery<recordByCode,recordByCodeVariables>(QUERY_RECORD_BY_CODE, {
        variables: {
            code: code!
        }
    })

    const [recordActivate] = useMutation<recordActivate,recordActivateVariables>(MUTATION_RECORD_ACTIVATE)

    const onComplete = async (data:IFormAct) => {
        await recordActivate({
            variables: {
                data: {
                    code: code!,
                    email: data.email,
                }
            },
            onCompleted: (res) => {
                if (res.recordActivate?.activated) {
                    navigate(ACT_SUCCESS)
                } else {
                    message.error('Ошибка активации сертификата. Попробуйте еще раз')
                }
            }
        })
    }

    // пока загружаем
    if (query.loading) {
        return (
                <div className={'content'}>
                    <Skeleton active={true}/>
                </div>
        )
    }

    // если активно
    if (query.data?.recordByCode?.activated) {
        return (
            <div className={cls.page}>
                <div className={'content'}>
                    <div className={cls.text}>
                        <h2 className={cls.success}><CheckCircleOutlined/> Ваш сертификат уже активирован</h2>
                        <Button type={'primary'} size={'large'} onClick={() => navigate(INDEX)}>Хорошо</Button>
                    </div>
                </div>

            </div>        )
    }

    return (
        <div>
            <div className={'content'}>
                {/*<Banners/>*/}
            </div>

            <FormLayout image={image}>
                <h2>Активация сертификата</h2>
                <ActForm FIO={query.data?.recordByCode?.FIO!} onComplete={onComplete}/>
            </FormLayout>

            <div className={'content'}>
                <Contacts/>
            </div>
        </div>
    );
}

export default Act;



