import React, {FC} from 'react';

import {Contacts} from '../index/Contacts/Contacts';

import cls from './About.module.scss'

const About: FC = () => (
    <div className={'content'}>
        {/*<Banners/>*/}
        <div className={cls.page}>
            <h2>О компании</h2>
            <p>
                FTS&nbsp;Group&nbsp;— международная группа компаний по&nbsp;оказанию разнообразных видов услуг, от&nbsp;юридических сервисов до&nbsp;помощи в&nbsp;подборе медицинских учреждений на&nbsp;территории&nbsp;РФ и&nbsp;СНГ.
            </p>
            <p>
                FTS&nbsp;Group&nbsp;— это онлайн-система для жизни, включающая в&nbsp;себя широкий спектр сервисных решений как для граждан, так и&nbsp;для бизнеса.
            </p>
            <p>
                Наши услуги востребованы как в&nbsp;России, так и&nbsp;в&nbsp;странах СНГ.
            </p>
            <p>
                Мы&nbsp;постоянно развиваемся, чтобы предложить вам максимально востребованный и&nbsp;полезный сервис. А&nbsp;наша команда высокопрофессиональных юристов с&nbsp;большим опытом разрешения проблем во&nbsp;всех сферах (отраслях) права доступна дистанционно.
            </p>
        </div>
        <Contacts/>
    </div>
);

export default About;



