import cn from 'classnames';

import cls from './Instruction.module.scss'

interface IBlock {
    outlined?: boolean,
    title: string,
    text: string,
    bg?: 2 | 3
}

export const Block = ({outlined, title, text, bg}: IBlock) => (
    <div className={cn([cls.block, outlined && cls.outlined, bg === 2 && cls.bgTwo, bg === 3 && cls.bgThree])}>
        <h3>{title}</h3>
        <div className={cls.text}>{text}</div>
    </div>
)