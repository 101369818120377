import React, {useEffect, useState} from 'react';

import bg from '../../images/gradient-01.jpg'

import cls from './FormLayout.module.scss'

interface IFormLayout {
    children?: React.ReactNode
    image?: string
}

function getWindowDimensions() {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

const FormLayout = ({children, image}: IFormLayout) => {

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const contentWidth = width > 1190 ? 1160 : width - 30
    const margin = (width - contentWidth) / 2
    const formWidth = 360;
    const imageWidth = 420;
    const left = ((width - margin - formWidth - imageWidth) / 2 + margin + formWidth)

    return (
        <div className={cls.background} style={{backgroundImage: `url(${bg})`}}>
            <div className={cls.wrapper} style={width >= 1024 ? {
                backgroundImage: `url(${image})`,
                backgroundPosition: `${left}px 50%`
            } : undefined}>

                <div className={'content'}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default FormLayout