import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {DownloadOutlined} from '@ant-design/icons';
import {Button} from 'antd';

import cls from './Success.module.scss'


export const SuccessWalkToMail = () => {

    const [searchParams] = useSearchParams();
    const fileUrl = searchParams.get('url')!


    return (
        <div className={cls.page}>
            <div className={'content'}>
                <div className={cls.text}>
                    <h2>Инструкция по возврату</h2>
                    <p>
                        Уважаемый Клиент,<br/>
                        Группа компаний «FTS Group» благодарит Вас за обращение.
                    </p>
                    <p>
                        Для возврата денежных средств за Сертификат, Вам необходимо:
                    </p>
                    <ol>
                        <li>
                            Сохранить, распечатать и подписать прилагаемое Заявление.
                        </li>
                        <li>
                            Направить подписанное заявление и копию Вашего сертификата Почтой России на адрес для
                            корреспонденции: 143003, Московская обл., г. Одинцово, ул. Маршала Жукова, д.32, а/я 15.
                        </li>
                    </ol>
                    <p>
                        Группа компаний «FTS Group» гарантирует возврат средств за сертификат в течение 14 рабочих
                        дней после получения полного пакета документов, в случае если это предусмотрено условиями
                        сертификата.
                    </p>
                    <p>
                        Обращаем Ваше внимание на то, что датой подачи заявления считается дата отправки письма Почтой
                        России.
                    </p>
                    <a href={fileUrl} target={'_blank'} rel={'noreferrer'}>
                        <Button type={'primary'} size={'large'} icon={<DownloadOutlined/>}>Скачать
                            Заявление.pdf</Button>
                    </a>
                </div>
            </div>

        </div>
    );
}


