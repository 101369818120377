import React, {FC} from 'react';
import { Link } from 'react-router-dom';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';

import {CONSULTATION, LOSS, REFUND} from '../../config/routes';
import {Contacts} from '../index/Contacts/Contacts';

import cls from './Faq.module.scss'

const items: CollapseProps['items'] = [
    {
        key: '1',
        label: 'Как вернуть деньги за сертификат?',
        children:
            <div>
                <p>Для возврата денежных средств за сертификат необходимо заполнить форму заявки по <Link to={REFUND}>ссылке</Link> и далее следовать инструкции на сайте.</p>
                <p>Обращаем Ваше внимание: возврат денежных средств возможен только в случае предоставления подписанных документов об отказе в течение 14 календарных дней с момента покупки сертификата.</p>
            </div>,
    },
    {
        key: '2',
        label: 'Где узнать номер сертификата?',
        children:
            <div>
                <p>Для уточнения номера сертификата Вам необходимо обратиться в кредитную организацию, оформившую сертификат.</p>
            </div>,
    },
    {
        key: '3',
        label: 'Где узнать реквизиты для заполнения заявки на возврат?',
        children:
            <div>
                <p>Реквизиты Вы можете уточнить в приложении Вашего Банка или узнать в отделении.</p>
                <p>Обращаем Ваше внимание: реквизиты обязательно должны принадлежать владельцу сертификата. Подойдет любой дебетовый, кредитный или накопительный счет физического лица - резидента Российской Федерации.</p>
            </div>,
    },
    {
        key: '4',
        label: 'В течение какого времени производится возврат денежных средств?',
        children:
            <div>
                <p>Возврат денежных средств за расторгнутый сертификат производится в течение 14 календарных дней с момента поступления от Вас подписанных документов на расторжение, в случае, если это предусмотрено условиями сертификата.</p>
            </div>,
    },
    {
        key: '5',
        label: 'Как узнать статус заявки на расторжение?',
        children:
            <div>
                <p>Для уточнения статуса Вашей заявки на расторжение сертификата необходимо направить письмо на адрес <a href={'mailto:help@fintechservice.group'} rel={'noreferrer'} target={'_blank'}>help@fintechservice.group</a></p>
                <p>В письме обязательно необходимо указать номер Вашего сертификата, полное ФИО владельца сертификата и способ подачи документов на расторжение. При отправке документов почтой России необходимо так же уточнить идентификационный номер почтового отправления.</p>
            </div>,
    },
    {
        key: '6',
        label: 'Где получить копию сертификата?',
        children:
            <div>
                <p>Копию сертификата Вы можете получить в кредитной организации, оформившей сертификат.</p>
                <p>Так же Вы можете направить запрос на адрес <a href={'mailto:help@fintechservice.group'} rel={'noreferrer'} target={'_blank'}>help@fintechservice.group</a></p>
                <p>В письме обязательно необходимо указать номер Вашего сертификата, дату оформления и полное ФИО владельца сертификата.</p>
            </div>,
    },
    {
        key: '7',
        label: 'Как получить услугу по сертификату?',
        children:
            <div>
                <p>Для получения услуги по сертификату необходимо заполнить форму заявки по <Link to={CONSULTATION}>ссылке</Link>.</p>
                <p>Обращаем Ваше внимание: за получением услуги может обратиться только владелец сертификата. Данные по перечню оказываемых услуг Вы можете проверить в Вашем сертификате.</p>
            </div>,
    },
    {
        key: '8',
        label: 'Как заявить страховой случай по сертификату?',
        children:
            <div>
                <p>Для оформления страхового случая по Вашему сертификату необходимо обратиться в страховую команию, оказывающую услуги страхования, входящие в Ваш сертификат.</p>
                <p>Телефонный номер горячей линии и наименование страховой организации указаны в Вашем сертификате.</p>
            </div>,
    },
    {
        key: '9',
        label: 'Как получить выплату по сертификату «Финансовая защита»?',
        children:
            <div>
                <p>Для получения выплаты по сертификату «Финансовая защита» внимательно ознакомьтесь с условиями сертификата и проверьте, подходит ли Ваша ситуация под условия выплаты.</p>
                <p>Далее перейдите по <Link to={LOSS}>ссылке</Link> на форму заявки и следуйте инструкции на сайте.</p>
            </div>,
    },
];

const Faq: FC = () => (
        <div className={'content'}>
            {/*<Banners/>*/}
            <div className={cls.page}>
                <h2>Часто задаваемые вопросы</h2>
                <Collapse items={items} accordion/>
            </div>
            <Contacts/>
        </div>
    )

export default Faq;



