import React, {FC, useState} from 'react';
import {Button, Checkbox, Form, Input} from 'antd';
import {MaskedInput} from 'antd-mask-input';

import {POLICY} from '../../config/routes';
import {IFormConsultation} from '../../shared/forms/forms';
import {certRegex, phoneRegex} from '../../shared/helpers/regex';

import cls from './Login.module.scss'

const Login: FC = () => {

    const [agree, setAgree] = useState<boolean>(true)
    const [form] = Form.useForm()

    const onFinish = (values:IFormConsultation) => {
        console.log(values)
    }

    return (
        <div className={'content'}>
            <div className={cls.page}>
                <h2>Вход в личный кабинет</h2>
                <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onSubmit'} onFinish={onFinish}>
                    <Form.Item name={'Certificate_Number'} label={'Номер сертификата'} rules={[
                        {required: true, message: 'Заполните, пожалуйста'},
                        {pattern: certRegex, message: 'Не соответствует формату номера сертификата'}
                    ]}>
                        <Input placeholder={'23000NSB176000000'}/>
                    </Form.Item>
                    <Form.Item name={'Phone'} label={'Номер телефона'} rules={[
                        {required: true, message: 'Заполните, пожалуйста'},
                        {pattern: phoneRegex, message: 'Не соответствует формату номера телефона'}
                    ]}>
                        <MaskedInput mask={'+7 (000) 000-00-00'} placeholder={'+7 (999) 888-77-66'} size={'large'}/>
                    </Form.Item>
                    <Form.Item>
                        <Checkbox checked={agree} onChange={() => setAgree(!agree)}>Согласен на обработку <a href={POLICY} rel={'noreferrer'} target={'_blank'}>персональных данных и с политикой конфиденциальности</a></Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button type={'primary'} htmlType={'submit'} disabled={!agree}>Войти</Button>
                    </Form.Item>

                </Form>
            </div>
        </div>
    )
}

export default Login;



