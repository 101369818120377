import {useState} from 'react';
import {Button, Checkbox, Form, Input} from 'antd'
import {MaskedInput} from 'antd-mask-input';

import {POLICY} from '../../config/routes';
import {IFormPartner} from '../../shared/forms/forms';
import {INNRegex, phoneRegex} from '../../shared/helpers/regex';

interface FormProps {
    onComplete: (values:IFormPartner) => void
}

const PartnerForm = ({onComplete}: FormProps) => {

    const [form] = Form.useForm()
    const [agree, setAgree] = useState<boolean>(true)

    const onFinish = (values:IFormPartner) => {
        onComplete(values)
    }

    return (
        <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onSubmit'} onFinish={onFinish}>
            <Form.Item name={'FIO'} label={'ФИО'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'Иванов Иван Иванович'}/>
            </Form.Item>
            <Form.Item name={'Company'} label={'Наименование компании'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'ЗАО «НЛМК»'}/>
            </Form.Item>
            <Form.Item name={'INN'} label={'ИНН'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: INNRegex, message: 'Не соответствует формату ИНН'}
            ]}>
                <Input placeholder={'7743013902'}/>
            </Form.Item>
            <Form.Item name={'Phone'} label={'Номер телефона'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: phoneRegex, message: 'Не соответствует формату номера телефона'}
            ]}>
                <MaskedInput mask={'+7 (000) 000-00-00'} placeholder={'+7 (999) 888-77-66'} size={'large'}/>
            </Form.Item>
            <Form.Item>
                <Checkbox checked={agree} onChange={() => setAgree(!agree)}>Согласен на обработку <a href={POLICY} target={'_blank'} rel={'noreferrer'}>персональных данных и с политикой конфиденциальности</a></Checkbox>
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} disabled={!agree}>Стать партнёром</Button>
            </Form.Item>
        </Form>
    )
}

export default PartnerForm