
import cls from './Contacts.module.scss'
export const Contacts = () => (
    <div className={cls.contacts}>
        <h2>Контакты</h2>
        <div className={cls.wrapper}>
            <div className={cls.mail}>
                <div className={cls.container}>
                    <div>Электронная почта</div>
                    <div className={cls.title}><a href={'mailto:help@fintechservice.group'}>help@fintechservice.group</a></div>
                </div>
            </div>
            {/*<div className={cls.mail}>*/}
            {/*    <div className={cls.container}>*/}
            {/*        <div>Адрес для почтовых обращений</div>*/}
            {/*        <div className={cls.title}>143003, Московская обл., г. Одинцово, ул. Маршала Жукова, д. 32, а/я 15</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>

        {/*<div className={cls.mail}>*/}
        {/*    <div className={cls.container}>*/}
        {/*        <div className={cls.title}>ООО «ИТ Консул»</div>*/}
        {/*        <div>ИНН 9724139250</div>*/}
        {/*        <div>КПП 772401001</div>*/}
        {/*        <div>ОГРН 1237700492204</div>*/}
        {/*        <div>Юридический адрес: 115230, г. Москва, вн. тер. г. Муниципальный округ Нагатино-Садовники, проезд Хлебозаводский, д. 7, стр. 9, помещение 24/Н</div>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </div>
)