import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {DownOutlined, RightOutlined, UpOutlined} from '@ant-design/icons';
import cn from 'classnames';

import cls from './Cards.module.scss'

interface IAction {
    name: string,
    link: string
}

interface ICard {
    img: string,
    title: string,
    text?: string,
    action?: IAction,
}

export const Card = ({img, title, text, action}: ICard) => {

    const [open, setOpen] = useState<boolean>(false)
    const navigate = useNavigate()

    return (
        <div className={cls.card}>
            <div className={cn([cls.wrapper, open && cls.opened])}>
                <div className={cls.content}>
                    <div className={cls.cover} style={{backgroundImage: `url(${img})`}}></div>
                    <h2>{title}</h2>
                    {text &&
                    <div onClick={() => setOpen(!open)} className={cls.open}>{open ? 'Скрыть' : 'Подробнее'} {open ?
                        <UpOutlined/> : <DownOutlined/>}</div>
                    }
                    {action &&
                        <div onClick={() => navigate(action.link)} className={cls.open}>{action.name} <RightOutlined/></div>
                    }
                </div>
                {open && <div className={cls.text}>{text}</div>}
            </div>
        </div>
    )
}