import {useState} from 'react';
import {Button, Checkbox, DatePicker, Form, Input} from 'antd'
import {MaskedInput} from 'antd-mask-input';

import {POLICY} from '../../config/routes';
import {IFormActivate} from '../../shared/forms/forms';
import {activateRegex, certRegex, phoneRegex} from '../../shared/helpers/regex';

interface FormProps {
    onComplete: (values:IFormActivate) => void
}

const ActivateForm = ({onComplete}: FormProps) => {

    const [form] = Form.useForm()
    const [agree, setAgree] = useState<boolean>(true)

    const onFinish = (values:IFormActivate) => {
        onComplete(values)
    }

    return (
        <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onSubmit'} onFinish={onFinish}>

            <Form.Item name={'Certificate_Number'} label={'Номер сертификата'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: certRegex, message: 'Не соответствует формату номера сертификата'},
                {pattern: activateRegex, message: 'Данный сертификат не подлежит активации'}
            ]}>
                <Input placeholder={'24WTM0000000000'}/>
            </Form.Item>

            <Form.Item name={'Certificate_Date'} label={'Дата выдачи сертификата'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <DatePicker format={'DD.MM.YYYY'}/>
            </Form.Item>

            <Form.Item name={'Device_Type'} label={'Тип устройства'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'Смартфорн, планшет, ноутбук'}/>
            </Form.Item>
            <Form.Item name={'Device_Name'} label={'Марка/Модель устройства'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'Apple MacBook Pro'}/>
            </Form.Item>
            <Form.Item name={'Device_Number'} label={'IMEI/SN'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'A00AA0AAAAA0'}/>
            </Form.Item>

            <Form.Item name={'FIO'} label={'ФИО'} rules={[{required: true, message: 'Заполните, пожалуйста'}]}>
                <Input placeholder={'Иванов Иван Иванович'}/>
            </Form.Item>
            <Form.Item name={'Phone'} label={'Номер телефона'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {pattern: phoneRegex, message: 'Не соответствует формату номера телефона'}
            ]}>
                <MaskedInput mask={'+7 (000) 000-00-00'} placeholder={'+7 (999) 888-77-66'} size={'large'}/>
            </Form.Item>

            <Form.Item>
                <Checkbox checked={agree} onChange={() => setAgree(!agree)}>Согласен на обработку <a href={POLICY} target={'_blank'} rel={'noreferrer'}>персональных данных и с политикой конфиденциальности</a></Checkbox>
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} disabled={!agree}>Активировать сертификат</Button>
            </Form.Item>
        </Form>
    )
}

export default ActivateForm