import {gql} from '@apollo/client';

export const CREATE_PARTNER = gql`
    mutation createPartner ($data: PartnerInput!) {
        createPartner(data: $data) {
            data {
                id
            }
        }
    }
`