import React, {FC} from 'react';
import {FileOutlined} from '@ant-design/icons';

import { OFFER_FIN, OFFER_LAW, OFFER_MED, OFFER_MED_ONLINE, OFFER_REFUND, POLICY} from '../../config/routes';
import {Contacts} from '../index/Contacts/Contacts';

import cls from './Document.module.scss'

const Document: FC = () => (
        <div className={'content'}>
            <div className={cls.page}>
                <h2>Документы</h2>
                <p><a href={POLICY} target={'_blank'} rel={'noreferrer'}><FileOutlined/> Политика конфиденциальности</a></p>
                <p><a href={OFFER_REFUND} target={'_blank'} rel={'noreferrer'}><FileOutlined/> Оферта Платные расторжения</a></p>
                <p><a href={OFFER_FIN} target={'_blank'} rel={'noreferrer'}><FileOutlined/> Оферта Финансовая защита</a></p>
                <p><a href={OFFER_MED} target={'_blank'} rel={'noreferrer'}><FileOutlined/> Оферта Правила абонентского обслуживания, онлайн консультация с врачом</a></p>
                <p><a href={OFFER_MED_ONLINE} target={'_blank'} rel={'noreferrer'}><FileOutlined/> Оферта Медицина онлайн</a></p>
                <p><a href={OFFER_LAW} target={'_blank'} rel={'noreferrer'}><FileOutlined/> Оферта Правила абонентского обслуживания, Телеюрист</a></p>
                {/*<p><a href={OFFER_ASTRO} target={'_blank'} rel={'noreferrer'}><FileOutlined/> Оферта Правила абонентского обслуживания, Телеастролог</a></p>*/}
            </div>
            <Contacts/>
        </div>
    )

export default Document;



