import translit from './translit';

const data = [
    // seven days
    {from: 3, to: 11, text: 'H3NSB059',},

    // mfo
    {from: 3, to: 11, text: 'H3NSB058',},
    {from: 5, to: 11, text: 'YYY029',},
    {from: 5, to: 11, text: 'YYY030',},
    {from: 5, to: 11, text: 'MED029',},
    {from: 5, to: 11, text: 'MED030',},

    // purchase place
    {from: 5, to: 11, text: 'WTM005',},
    {from: 5, to: 11, text: 'ANL005',},
    {from: 5, to: 11, text: 'ANT005',},

    // on personal
    {from: 5, to: 11, text: 'YYY044',},
    {from: 5, to: 11, text: 'YYY045',},
    {from: 5, to: 11, text: 'YYY046',},
    {from: 5, to: 11, text: 'YYY048',},
    {from: 5, to: 11, text: 'YYY049',},
    {from: 5, to: 11, text: 'YYY055',},
    {from: 5, to: 11, text: 'YYY056',},

    {from: 5, to: 11, text: 'MED044',},
    {from: 5, to: 11, text: 'MED045',},
    {from: 5, to: 11, text: 'MED046',},
    {from: 5, to: 11, text: 'MED048',},
    {from: 5, to: 11, text: 'MED049',},
    {from: 5, to: 11, text: 'MED055',},
    {from: 5, to: 11, text: 'MED056',},

    {from: 5, to: 11, text: 'AST044',},
    {from: 5, to: 11, text: 'AST045',},
    {from: 5, to: 11, text: 'AST046',},
    {from: 5, to: 11, text: 'AST048',},
    {from: 5, to: 11, text: 'AST049',},
    {from: 5, to: 11, text: 'AST055',},
    {from: 5, to: 11, text: 'AST056',},

    {from: 5, to: 11, text: 'PSY044',},
    {from: 5, to: 11, text: 'PSY045',},
    {from: 5, to: 11, text: 'PSY046',},
    {from: 5, to: 11, text: 'PSY048',},
    {from: 5, to: 11, text: 'PSY049',},
    {from: 5, to: 11, text: 'PSY055',},
    {from: 5, to: 11, text: 'PSY056',},


]

export function cerstWithoutDetails (number:string) {

    let result = false;

    data.forEach((item) => {
        if (result) {
            return;
        }

        if (String(translit(number)).substring(item.from, item.to) === item.text.toLowerCase()) {
            result = true;
        }


    });
    
    return result;
}