import React from 'react';
import {useNavigate} from 'react-router-dom';
import {CheckCircleOutlined} from '@ant-design/icons';
import { Button } from 'antd';

import {INDEX} from '../../config/routes';

import cls from '../Refund/Free/Success/Success.module.scss'


export const ActSuccess = () => {

    const navigate = useNavigate()

    return (
        <div className={cls.page}>
            <div className={'content'}>
                <div className={cls.text}>
                    <h2 className={cls.success}><CheckCircleOutlined/> Успешно</h2>
                    <p>
                        Уважаемый Клиент,<br/>
                        Группа компаний «FTS Group» благодарит Вас за обращение.
                    </p>
                    <p>
                        Поздравляем, ваш сертификат успешно активирован и направлен на указанную электронную почту.
                    </p>
                    <Button type={'primary'} size={'large'} onClick={() => navigate(INDEX)}>Хорошо</Button>
                </div>
            </div>

        </div>
    )}


