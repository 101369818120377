import {gql} from '@apollo/client';

export const CREATE_CONSULTATION = gql`
    mutation createConsultation ($data: ConsultationInput!) {
        createConsultation(data: $data) {
            data {
                id
            }
        }
    }
`