import {gql} from '@apollo/client';

export const CREATE_REFUND_REQUEST = gql`
    mutation createRefundRequest ($data: RefundRequestInput!) {
        createRefundRequest(data: $data) {
            data {
                id
                attributes {
                    template
                    requestPdfFileUrl
                }
            }
        }
    }
`