import {Outlet} from 'react-router-dom';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

import cls from './Layout.module.scss';

const Layout = () => (
    <>
        <div className={cls.layout}>

                <Header/>

            <div className={cls.content}>
                <Outlet/>
            </div>

                <Footer/>

        </div>
    </>
)

export default Layout