import avatar1 from './avatar1.png'
import avatar2 from './avatar2.png'
import avatar3 from './avatar3.png'
import avatar4 from './avatar4.png'
import {Review} from './Review';

import cls from './Reviews.module.scss'

export const Reviews = () => (
    <div className={cls.block}>
        <h2>Мы помогаем нашим клиентам</h2>
        <div className={cls.reviews}>
            <Review avatar={avatar1} name={'Екатерина'} text={'Отличный юрист. Я\xA0обратилась в\xA0эту компанию по\xA0поводу сложного наследственного дела и\xA0была приятно удивлена квалификацией и\xA0профессионализмом юриста, который работал над моим делом. Он\xA0был внимателен к\xA0моим потребностям и\xA0настойчив в\xA0достижении наилучшего результата. Я\xA0очень довольна его работой и\xA0рекомендую эту компанию всем, кто нуждается в\xA0качественных юридических услугах.'}/>
            <Review avatar={avatar2} name={'Николай'} text={'Опытные специалисты. Я\xA0работал с\xA0этой компанией несколько раз по\xA0различным юридическим вопросам, и\xA0каждый раз я\xA0был впечатлен их\xA0профессионализмом и\xA0опытом. Сотрудники этой компании всегда были готовы помочь мне с\xA0любыми юридическими вопросами и\xA0проблемами. Я\xA0рекомендую эту компанию всем, кто ищет надежных и\xA0опытных юристов.'}/>
            <Review avatar={avatar3} name={'Евгений'} text={'Отличное обслуживание клиентов. Я\xA0был очень доволен обслуживанием в\xA0этой компании. Сотрудники быстро ответили на\xA0мои вопросы и\xA0предоставили мне всю необходимую информацию. Они были очень внимательны и\xA0приветливы, и\xA0всегда готовы помочь мне с\xA0любыми вопросами. Я\xA0рекомендую эту компанию всем, кто ищет высококачественное обслуживание клиентов.'}/>
            <Review avatar={avatar4} name={'Алексей'} text={'Надежные юристы. Я\xA0обратился в\xA0эту компанию для решения сложной юридической проблемы, и\xA0мне очень понравилось, как они подошли к\xA0моей ситуации. Сотрудники были очень профессиональны и\xA0надежны, и\xA0я\xA0чувствовал себя в\xA0безопасности, работая с\xA0ними. Я\xA0настоятельно рекомендую эту компанию всем, кто ищет надежных юристов для решения своих юридических проблем.'}/>
        </div>
    </div>
)