import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import dayjs from 'dayjs';

import {LossInput} from '../../__generated__/global-types';
import FormLayout from '../../components/FormLayout/FormLayout';
import {ACTIVATE_SUCCESS} from '../../config/routes';
import {createActivate, createActivateVariables} from '../../shared/graphql/__generated__/createActivate';
import {CREATE_ACTIVATE} from '../../shared/graphql/createActivate';
import {Contacts} from '../index/Contacts/Contacts';

import image from './activate.svg';
import ActivateForm from './ActivateForm';


const Activate: FC = () => {

    const navigate = useNavigate();
    const [createActivate] = useMutation<createActivate,createActivateVariables>(CREATE_ACTIVATE)

    const onComplete = async (data:LossInput) => {
        await createActivate({
            variables: {data: {...data, Certificate_Date: dayjs(data.Certificate_Date).format('YYYY-MM-DD')}},
            onCompleted: () => {
                navigate(ACTIVATE_SUCCESS)
            }
        })
    }

    return (
        <div>
            <div className={'content'}>
                {/*<Banners/>*/}
            </div>

            <FormLayout image={image}>
                <h2>Активация сертификата</h2>
                <ActivateForm onComplete={onComplete}/>
            </FormLayout>

            <div className={'content'}>
                <Contacts/>
            </div>
        </div>
    );
}

export default Activate;



