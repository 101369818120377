import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';

import {PartnerInput} from '../../__generated__/global-types';
import FormLayout from '../../components/FormLayout/FormLayout';
import { PARTNER_SUCCESS} from '../../config/routes';
import {createPartner, createPartnerVariables} from '../../shared/graphql/__generated__/createPartner';
import {CREATE_PARTNER} from '../../shared/graphql/createPartner';
import {Contacts} from '../index/Contacts/Contacts';

import image from './partner.svg';
import PartnerForm from './PartnerForm';

import cls from './Partner.module.scss'

const Partner: FC = () => {

    const navigate = useNavigate();
    const [createPartner] = useMutation<createPartner,createPartnerVariables>(CREATE_PARTNER)

    const onCompleted = async (data:PartnerInput) => {
        await createPartner({
            variables: {data: data},
            onCompleted: () => {
                navigate(PARTNER_SUCCESS)
            }
        })
    }

    return (
        <div>
            <div className={'content'}>
                {/*<Banners/>*/}
                <div className={cls.page}>
                    <h2>Станьте нашим партнёром</h2>
                    <p>
                        Предлагаем Вам сотрудничество с&nbsp;международной прогрессивной компанией FTS&nbsp;Group.
                    </p>
                    <p>
                        Создайте для своих Клиентов полезный продукт, реализуя сертификат на&nbsp;наши сервисные услуги. Позаботьтесь о&nbsp;своих клиентах, оформив партнёрство с&nbsp;нами, и&nbsp;получите дополнительный доход. Сделайте свой продукт более привлекательным для Клиентов в&nbsp;условиях жесткой конкуренции.
                    </p>
                    <p>
                        Для получения подробной информации о&nbsp;партнёрской программе, заполните форму на&nbsp;сайте&nbsp;&mdash; наши менеджеры свяжутся с&nbsp;Вами!
                    </p>
                    <p>
                        Все сервисы и&nbsp;услуги доступны как на&nbsp;территории Российской Федерации, так и&nbsp;на&nbsp;территории Республики Казахстан.
                    </p>
                </div>
            </div>

            <FormLayout image={image}>
                <h2>Стать партнёром</h2>
                <PartnerForm onComplete={onCompleted}/>
            </FormLayout>

            <div className={'content'}>
                <Contacts/>
            </div>
        </div>
    );
}

export default Partner;



