import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Button } from 'antd';

import { REFUND_FREE} from '../../../../config/routes';

import cls from '../Success/Success.module.scss'


export const ErrorCertNumber = () => {

    const navigate = useNavigate()

    return (
    <div className={cls.page}>
        <div className={'content'}>
            <div className={cls.text}>
                <h2>Ошибка номера сертификата</h2>
                <p>
                    Уважаемый Клиент,<br/>
                    Группа компаний «FTS Group» благодарит Вас за обращение.
                </p>
                <p>
                    Вы указали неверный номер сертификата, попробуйте еще раз.
                </p>
                <Button type={'primary'} size={'large'} onClick={() => navigate(REFUND_FREE)}>Хорошо</Button>
            </div>
        </div>

    </div>
)}


