import {gql} from '@apollo/client';

export const CREATE_ACTIVATE = gql`
    mutation createActivate ($data: ActivateInput!) {
        createActivate(data: $data) {
            data {
                id
            }
        }
    }
`