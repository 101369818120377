import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Button } from 'antd';

import {INDEX} from '../../../../config/routes';

import cls from './Success.module.scss'


export const SuccessWalkToOffice = () => {

    const navigate = useNavigate()

    return (
    <div className={cls.page}>
        <div className={'content'}>
            <div className={cls.text}>
                <h2>Инструкция по возврату</h2>
                <p>
                    Уважаемый Клиент,<br/>
                    Группа компаний «FTS Group» благодарит Вас за обращение.
                </p>
                <p>
                    За возвратом страховой премии по договору страхования Вам необходимо обратиться в ближайший офис САО «ВСК» или направить письмо с уведомлением Почтой России на адрес САО «ВСК»: 121552, г. Москва, ул. Островная, дом 4
                </p>
                <Button type={'primary'} size={'large'} onClick={() => navigate(INDEX)}>Хорошо</Button>
            </div>
        </div>

    </div>
)}


