import {Block} from './Block';

import cls from './Instruction.module.scss'

export const Instruction = () => (
    <div className={cls.instruction}>
        <h2>Пользоваться нашим сервисом — просто</h2>
        <div className={cls.blocks}>
            <Block title={'Вы приобрели нашу юридическую помощь'} text={'и хотите ей воспользоваться - наши тарифы не нуждаются в активации'} outlined/>
            <Block title={'1. Оставьте заявку'} text={'на получение консультации и наши менеджеры свяжутся с вами для уточнения деталей'} />
            <Block title={'2. Получите результат'} text={'Проконсультируем, подготовим документы, защитим интересы компании'} bg={2}/>
            <Block title={'3. Обратная связь'} text={'менеджер попросит дать обратную связь о проведенной консультации'} bg={3}/>
        </div>
    </div>
)