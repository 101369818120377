import {gql} from '@apollo/client';

export const CREATE_LOSS = gql`
    mutation createLoss ($data: LossInput!) {
        createLoss(data: $data) {
            data {
                id
            }
        }
    }
`