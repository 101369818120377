import {useState} from 'react';
import {Button, Checkbox, Form, Input} from 'antd'

import {POLICY} from '../../config/routes';
import {IFormAct} from '../../shared/forms/forms';

interface FormProps {
    FIO: string
    onComplete: (values:IFormAct) => void
}

const ActForm = ({FIO, onComplete}: FormProps) => {

    const [form] = Form.useForm()
    const [agree, setAgree] = useState<boolean>(true)

    const onFinish = (values:IFormAct) => {
        onComplete(values)
    }

    return (
        <Form form={form} layout={'vertical'} size={'large'} validateTrigger={'onSubmit'} onFinish={onFinish}>
            
            <Form.Item label={'ФИО'}>
                <Input placeholder={'Иванов Иван Иванович'} disabled={true} value={FIO}/>
            </Form.Item>

            <Form.Item name={'email'} label={'Email'} rules={[
                {required: true, message: 'Заполните, пожалуйста'},
                {type: 'email', message: 'Не соответствует формату email'},
            ]}>
                <Input placeholder={'name@example.com'}/>
            </Form.Item>

            <Form.Item>
                <Checkbox checked={agree} onChange={() => setAgree(!agree)}>Согласен на обработку <a href={POLICY} target={'_blank'} rel={'noreferrer'}>персональных данных и с политикой конфиденциальности</a></Checkbox>
            </Form.Item>

            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} disabled={!agree}>Активировать сертификат</Button>
            </Form.Item>

        </Form>
    )
}

export default ActForm