import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {DownloadOutlined} from '@ant-design/icons';
import {Button} from 'antd';

import {getApiBase} from '../../../../env';

import cls from './Success.module.scss'

export const SuccessDefaultRequest = () => {

    const [searchParams] = useSearchParams();
    const fileUrl = `${getApiBase()}/uploads/pdf/${searchParams.get('url')}`

    return (
        <div className={cls.page}>
            <div className={'content'}>
                <div className={cls.text}>
                    <h2>Инструкция по возврату</h2>
                    <p>
                        Уважаемый Клиент,<br/>
                        Группа компаний «FTS Group» благодарит Вас за обращение.
                    </p>
                    <p>
                        Для возврата денежных средств за Сертификат, Вам необходимо:
                    </p>
                    <ol>
                        <li>
                            Сохранить, распечатать и подписать прилагаемое Заявление.
                        </li>
                        <li>
                            Отправить письмо на <a href={'mailto:help@fintechservice.group'}>help@fintechservice.group</a>:
                            <ul>
                                <li>Cкан-копию заполненного и подписанного заявления;</li>
                                <li>копию сертификата, который нужно расторгнуть</li>
                            </ul>
                        </li>
                    </ol>
                    <p>
                        Группа компаний «FTS Group» гарантирует возврат средств за сертификат после получения заполненного Заявления, в случае если это предусмотрено условиями сертификата.
                    </p>
                    <a href={fileUrl} target={'_blank'} rel={'noreferrer'}>
                        <Button type={'primary'} size={'large'} icon={<DownloadOutlined/>}>Скачать
                            Заявление.pdf</Button>
                    </a>
                </div>
            </div>

        </div>
    );
}


