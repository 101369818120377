import {gql} from '@apollo/client';

export const QUERY_SMS_BY_CODE = gql`
    query smsByCode ($code: String!) {
        smsByCode(code: $code) {
            FIO
            Phone
            activated
        }
    }
`

export const MUTATION_SMS_ACTIVATE = gql`
    mutation smsActivate ($data: smsActivateInput!) {
        smsActivate(data: $data) {
            FIO
            activated
        }
    }
`