import React, {FC} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {CheckCircleOutlined} from '@ant-design/icons';
import {useMutation, useQuery} from '@apollo/client';
import {Button, message, Skeleton} from 'antd';

import FormLayout from '../../components/FormLayout/FormLayout';
import { INDEX, SMS_SUCCESS} from '../../config/routes';
import {smsActivate, smsActivateVariables} from '../../shared/graphql/__generated__/smsActivate';
import {smsByCode, smsByCodeVariables} from '../../shared/graphql/__generated__/smsByCode';
import {MUTATION_SMS_ACTIVATE, QUERY_SMS_BY_CODE} from '../../shared/graphql/smsActivate';
import {phonePlainToInput} from '../../shared/helpers/phoneFormat';
import {Contacts} from '../index/Contacts/Contacts';

import image from './sms.svg'
import SmsForm from './SmsForm';

import cls from './Sms.module.scss';

const Sms: FC = () => {

    const navigate = useNavigate();
    const {code} = useParams();
    const query = useQuery<smsByCode,smsByCodeVariables>(QUERY_SMS_BY_CODE, {
        variables: {
            code: code!
        }
    })

    const [smsActivate] = useMutation<smsActivate,smsActivateVariables>(MUTATION_SMS_ACTIVATE)

    const onComplete = async () => {
        await smsActivate({
            variables: {
                data: {
                    code: code!,
                }
            },
            onCompleted: (res) => {
                if (res.smsActivate?.activated) {
                    navigate(SMS_SUCCESS)
                } else {
                    message.error('Ошибка активации услуги СМС-Информирование. Попробуйте еще раз')
                }
            }
        })
    }

    // пока загружаем
    if (query.loading) {
        return (
                <div className={'content'}>
                    <Skeleton active={true}/>
                </div>
        )
    }

    // если активно
    if (query.data?.smsByCode?.activated) {
        return (
            <div className={cls.page}>
                <div className={'content'}>
                    <div className={cls.text}>
                        <h2 className={cls.success}><CheckCircleOutlined/> Услуга SMS-Информирования уже активирована</h2>
                        <Button type={'primary'} size={'large'} onClick={() => navigate(INDEX)}>Хорошо</Button>
                    </div>
                </div>

            </div>        )
    }

    return (
        <div>
            <div className={'content'}>
                {/*<Banners/>*/}
            </div>

            <FormLayout image={image}>
                <h2>Активация услуги<br/>
                    СМС-Информирование</h2>
                <SmsForm FIO={query.data?.smsByCode?.FIO!} Phone={phonePlainToInput(query.data?.smsByCode?.Phone!)} onComplete={onComplete}/>
            </FormLayout>

            <div className={'content'}>
                <Contacts/>
            </div>
        </div>
    );
}

export default Sms;



