function translit(word:string) {
    const converter = {
        'а': 'a',    'б': 'b',    'в': 'b',    'г': 'g',    'д': 'd',
        'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': '3',    'и': 'i',
        'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'h',
        'о': 'o',    'п': 'p',    'р': 'p',    'с': 'c',    'т': 't',
        'у': 'y',    'ф': 'f',    'х': 'x',    'ц': 'c',    'ч': 'ch',
        'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
        'э': 'e',    'ю': 'yu',   'я': 'ya'
    };

    word = String(word).toLowerCase();

    let answer = '';
    for (let i = 0; i < word.length; ++i ) {
        // @ts-ignore
        if (converter[word[i]] === undefined){
            answer += word[i];
        } else {
            // @ts-ignore
            answer += converter[word[i]];
        }
    }

    answer = answer.replace(/[^-0-9a-z]/g, '-');
    answer = answer.replace(/[-]+/g, '-');
    answer = answer.replace(/^-|-$/g, '');
    return answer;
}

export default translit;
