import {ACTIVATE} from '../../../config/routes';

import {Card} from './Card';
import img_doctor from './doctor.jpg'
import img_fin_protect from './fin-protect.jpg'
import img_lawyer from './lawyer.jpg'
import img_oldlikenew from './oldlikenew.jpg'
import img_waranty from './waranty.jpg'

import cls from './Cards.module.scss'

export const Cards = () => (
    <>
        <div className={cls.cards}>
            <Card img={img_fin_protect} text={'Погашение остатка ссудной задолженности, в случае непредвиденных обстоятельств'}
                  title={'Финансовая защита'}/>
            <Card img={img_waranty} title={'Гарантия 12/24 месяца'}
                  action={{name: 'Активировать сертификат', link: ACTIVATE}}/>
            <Card img={img_oldlikenew} text={'Сервис для не новых устройств, позволяющий отремонтировать повреждения возникшие в результате внешнего воздействия, в том числе жидкостью или гарантийных поломок, а также получить компенсацию в случае утраты устройства в результате противоправных действий третьих лиц'}
                  title={'Старый как новый'}/>
        </div>
        <div className={cls.cards}>
            <Card img={img_lawyer} text={'Квалифицированная юридическая помощь гражданам и юридическим лицам'}
                  title={'Телеюрист'}/>
            <Card img={img_doctor}
                  text={'Подбор медицинского учреждения, подбор лабораторий, консультации по получению налогового вычета'}
                  title={'Медицинская помощь онлайн'}/>
        </div>
    </>
)