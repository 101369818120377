import React from 'react';

import cls from './Reviews.module.scss'

interface IReview {
    avatar: string,
    name: string,
    text: React.ReactNode,
}

export const Review = ({avatar, name, text}: IReview) => (
    <div className={cls.review}>
        <div className={cls.left}>
            <div className={cls.avatar} style={{backgroundImage: `url(${avatar})`}}/>
        </div>
        <div className={cls.right}>
            <div className={cls.name}>{name}</div>
            <div className={cls.text}>{text}</div>
        </div>
    </div>
)