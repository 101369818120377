import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';
import dayjs from 'dayjs'

import FormLayout from '../../components/FormLayout/FormLayout';
import {CONSULTATION_SUCCESS} from '../../config/routes';
import {IFormConsultation} from '../../shared/forms/forms';
import {createConsultation, createConsultationVariables} from '../../shared/graphql/__generated__/createConsultation';
import {CREATE_CONSULTATION} from '../../shared/graphql/createConsultation';
import {Contacts} from '../index/Contacts/Contacts';

import ConsultationForm from './ConsultationForm';
import image from './phone.svg';

const Consultation: FC = () => {

    const navigate = useNavigate();
    const [createConsultation] = useMutation<createConsultation, createConsultationVariables>(CREATE_CONSULTATION)

    const onComplete = async (data: IFormConsultation) => {
        await createConsultation({
            variables: {data: {...data, Certificate_Date: dayjs(data.Certificate_Date).format('YYYY-MM-DD')}},
            onCompleted: () => {
                navigate(CONSULTATION_SUCCESS)
            }
        })
    }

    return (
        <div>
            <div className={'content'}>
                {/*<Banners/>*/}
            </div>

            <FormLayout image={image}>
                <h2>Онлайн консультация</h2>
                <ConsultationForm onComplete={onComplete}/>
            </FormLayout>

            <div className={'content'}>
                <Contacts/>
            </div>
        </div>
    );
}

export default Consultation;



