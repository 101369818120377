import {gql} from '@apollo/client';

export const QUERY_RECORD_BY_CODE = gql`
    query recordByCode ($code: String!) {
        recordByCode(code: $code) {
            FIO
            activated
        }
    }
`

export const MUTATION_RECORD_ACTIVATE = gql`
    mutation recordActivate ($data: recordActivateInput!) {
        recordActivate(data: $data) {
            FIO
            activated
        }
    }
`